import React from 'react';

import {
  getReputationScoreLabel,
  getReputationScoreDefinition,
} from '@utils';

import './style.css';

type ReputationScoreContentType = {
  score: number;
};

const ReputationScoreContent = ({
  score,
}: ReputationScoreContentType): JSX.Element => {

  const legendList = [
    {
      value: 1,
      range: 'Poor 0-39',
    },
    {
      value: 50,
      range: 'Weak 40-59',
    },
    {
      value: 66,
      range: 'Average 60-69',
    },
    {
      value: 78,
      range: 'Strong 70-79',
    },
    {
      value: 99,
      range: 'Excellent 80+',
    },
  ];

  const scoreLabel = getReputationScoreLabel(score);
  const scoreDefinition = getReputationScoreDefinition(score);
  const scoreFixed = Number(score).toFixed(1) || score;

  return (
    <div className="reputation-score-content--main">
      <section className="reputation-score-content__left-side">
        <h3>{scoreFixed}</h3>
        <div
          className={`card-score__label-${scoreLabel}`}
        >
          {scoreLabel}
        </div>
      </section>
      <section className="reputation-score-content__right-side">
        <div>
          <p>
            <strong>{scoreLabel} - </strong>
            {scoreDefinition}
          </p>
        </div>
        <ul>
          {
            legendList.map( ({ value, range }) => {
              const key = `legend-item-${value}`;
              const oneScore = getReputationScoreLabel(value);
              const classes = `card-score__label-${oneScore}`;
              return (
                <li key={key} className={classes} >
                  {range}
                </li>
              )
            })
          }
        </ul>
      </section>
    </div>
  )
}

export default ReputationScoreContent;
