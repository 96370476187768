import React from 'react';

import ReputationScoreContent from '@elements/ReputationScoreContent';
import RichText from '@elements/RichText';

import './style.css';

type AnalysisProps = {
  contentful_id: string,
  year: number,
  analysis: {
    raw: string,
  },
  slug: string,
};

type CardScoreType = {
  score: number,
  year: number,
  analysis: AnalysisProps[],
  slug: string,
};

const CardScore = ({
  score,
  year,
  analysis,
  slug,
}: CardScoreType): JSX.Element => {
  const oneAnalysis = analysis?.length > 0 
    && analysis
        ?.find( ({ year: oneYear }) => oneYear === year )
        ?.analysis;

  return (
    <blockquote className="card-score--main">
      <div className="card-score__top">
        <hr />
        <h2>{year} {slug.startsWith('rankings-esg') ? "ESG Score" : "Reputation Score"}</h2>
      </div>
      <ReputationScoreContent score={score} />
      {
        oneAnalysis && (
          <div className="card-score__analysis">
            <h2>Reputation Analysis</h2>
            <RichText
              bodyRichText={oneAnalysis}
            />
          </div>
        )
      }
    </blockquote>
  );
};

export default CardScore;