import React from 'react';

import Button from '@elements/Button';
import CardScore from '@elements/CardScore';
import Dropdown from '@elements/Dropdown';
import SocialIcons from '@elements/SocialIcons';
import Hero from '@sections/Hero';
import LogoLeft from '@svg/icons/caret-left.svg';
import LogoRight from '@svg/icons/caret-right.svg';
import LogoDown from '@svg/icons/download.svg';
import { getHeroInfo, getDropDownListfrCompanies } from '@utils';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import '../styles/templates/company.css';

type RankingProps = {
  contentful_id: string;
  reportLink: {
    url: string;
    label: string;
  };
  year: number;
  companyRankings: Array<{ companyId: number }>;
  scoreImageCompany: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
};

type CompanyProps = {
  data: {
    ranking: RankingProps;
    rankingList: {
      companyImage: {
        title: string;
        gatsbyImageData: IGatsbyImageData;
      };
      mainSlug: string;
      useFilterHeroCompany: boolean;
      rankings: RankingProps[];
      activeRanking: RankingProps;
      linkTitle: string;
    };
    company: {
      contentful_id: string;
      slug: string;
      displayName: string;
      iconCompany: {
        title: string;
        gatsbyImageData: IGatsbyImageData;
      };
      lifestyle: {
        title: string;
        gatsbyImageData: IGatsbyImageData;
      };
      rankingAnalysis: {
        contentful_id: string;
      };
      description: {
        description: string;
      };
      companyId: number;
    };
  };
  pageContext: {
    position: number;
    score: number;
    prevCompany: {
      contentful_id: string;
      displayName: string;
      position: number;
      companySlug: string;
    };
    nextCompany: {
      contentful_id: string;
      displayName: string;
      position: number;
      companySlug: string;
    };
    companyId: number;
  };
};

const Company = ({
  data: { rankingList, ranking, company },
  pageContext: { position, score, prevCompany, nextCompany },
}: CompanyProps): JSX.Element => {
  const {
    companyImage,
    mainSlug,
    useFilterHeroCompany,
    rankings,
    linkTitle,
    activeRanking: {
      contentful_id: rankingActiveId,
    }
  } = rankingList;

  const {
    contentful_id: rankingSelectId,
    reportLink,
    year,
    scoreImageCompany,
  } = ranking;

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    contentful_id,
    slug,
    displayName,
    iconCompany,
    lifestyle,
    description,
    rankingAnalysis,
  } = company;

  const socialList = {
    titles: ['linkedIn', 'twitter', 'facebook'],
    commonLink: `/${mainSlug}/company/${slug}/`,
    type: 'share',
    id: contentful_id,
  };

  const heroInfo = getHeroInfo(companyImage, undefined, displayName, undefined, useFilterHeroCompany);
  const iconImage = getImage(iconCompany?.gatsbyImageData);
  const lifestyleImage = getImage(lifestyle?.gatsbyImageData);
  const scoreImage = getImage(scoreImageCompany?.gatsbyImageData);
  const dropDownList = getDropDownListfrCompanies(rankings, rankingSelectId, rankingActiveId, company);
  const dropDownText = rankingActiveId === rankingSelectId 
    ? "Previous Years"
    : "Years";
  const linkRanking = rankingActiveId === rankingSelectId
    ? `/${mainSlug}/`
    : `/${mainSlug}/${year}/`;

  return (
    <>
      <div className='company__hero'>
        <Hero extra={heroInfo.extra} item={heroInfo.item} />
      </div>
      <div className='company__inner'>
        <section className='company__top-section'>
          <div className='company__social'>
            <span>Share:</span>
            <SocialIcons socialList={socialList} />
          </div>
          <figure className='company__icon'>
            <GatsbyImage alt={iconCompany?.title} image={iconImage} />
          </figure>
          <figure className='company__lifestyle'>
            <GatsbyImage alt={lifestyle?.title} image={lifestyleImage} />
          </figure>
          <figure className='company__score-image'>
            <GatsbyImage alt={scoreImageCompany?.title} image={scoreImage} />
            <h2 className='company__score-title'>#{position}</h2>
          </figure>
        </section>
        <section className='company__description'>
          <p>{description?.description}</p>
          {reportLink && (
            <div className='btn--large'>
              <Button childClassName='btn--green-white' href={reportLink.url}>
                {reportLink.label}
                <LogoDown />
              </Button>
            </div>
          )}
          {
            dropDownList.length > 0 && (
              <Dropdown
                childClassName="company__dropdown"
                listChilds={dropDownList}
              >
                {dropDownText}
              </Dropdown>
            )
          }
        </section>
        <CardScore analysis={rankingAnalysis} score={score} year={year} slug={mainSlug} />
        <section className='company__bottom-section'>
          <div className='company__bottom-section__btn-lside'>
            {prevCompany && (
              <Button childClassName='btn--icon-text' href={`/${mainSlug}/company/${prevCompany?.companySlug}`}>
                <span>
                  <LogoLeft />
                  {`#${prevCompany?.position} `}
                  {prevCompany?.displayName}
                </span>
              </Button>
            )}
          </div>
          <div className='company__bottom-section__btn-mside'>
            {mainSlug && <Button href={linkRanking}>View the {year} {linkTitle}</Button>}
          </div>
          <div className='company__bottom-section__btn-rside'>
            {nextCompany && (
              <Button childClassName='btn--icon-text' href={`/${mainSlug}/company/${nextCompany?.companySlug}`}>
                <span>
                  {`#${nextCompany?.position} `}
                  {nextCompany?.displayName}
                  <LogoRight />
                </span>
              </Button>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Company;

export const queryCompany = graphql`
  query QUERY_COMPANY(
    $rankingListId: String!
    $rankingId: String!
    $companyId: Int!
  ) {
    navigation: contentfulNavigationNavMenu(
      contentful_id: { eq: "28QWR4Rx641pUxsQaVNB1m" }
    ) {
      ...NavMenu
    }

    seo: contentfulCompany(companyId: { eq: $companyId }) {
      ...CompanySeo
    }

    company: contentfulCompany(companyId: { eq: $companyId }) {
      ...CompanyFull
    }

    rankingList: contentfulRankingList(contentful_id: { eq: $rankingListId }) {
      ...RankingListFull
    }

    ranking: contentfulRanking(contentful_id: { eq: $rankingId }) {
      ...RankingFull
    }
  }
`;
